<template>
  <div
    v-if="loading.page"
    class="app-flex justify-center align-center h-vh-100"
  >
    <v-progress-circular indeterminate color="primary" />
  </div>
  <v-card v-else outlined tile class="w-100">
    <vue-perfect-scrollbar>
      <v-card-text class="h-without-header-footer">
        <slack />
        <lines />
      </v-card-text>
    </vue-perfect-scrollbar>
  </v-card>
</template>

<script>
import Slack from './components/Slack'
import lines from './components/Line'
import Snackbar from 'Helpers/snackbar/index'
export default {
  components: {
    Slack,
    lines,
  },
  data() {
    return {
      loading: {
        page: true,
      },
    }
  },
  mounted() {
    this.getSlackRedirectStatus()
  },
  methods: {
    getSlackRedirectStatus() {
      switch (this.$route.query.slack) {
        case undefined:
          break
        case 'success':
          Snackbar.success('成功')
          break
        case 'error':
          // eslint-disable-next-line vue/no-parsing-error
          Snackbar.error('エラーが発生しました')
          break
      }
      this.loading.page = false
    },
  },
}
</script>
